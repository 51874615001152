import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Characteristic from '../components/Characteristic'
import Usps from '../components/Usps'
import Gallery from '../components/Gallery'
import Addresses from '../components/Addresses'
import Hint from '../components/Hint'

import Award from '../components/Pages/MiniCox/Award'

import '../scss/styles.scss'

const MiniCox = () => {
  return (
    <DefaultLayout
      title={siteData.miniCox.title}
      description={siteData.miniCox.description}
      activePage={Pages.MINI_COX}
    >
      <Section className="headline">
        <Container>
          <h1 className="title">{siteData.miniCox.headline}</h1>
          <h2 className="subheadline">{siteData.miniCox.subheadline}</h2>
          <Paragraph className="protection">
            {siteData.miniCox.protection}
          </Paragraph>
        </Container>
      </Section>

      <Usps
        imageSrc={`../${siteData.miniCox.uspImg}`}
        usps={siteData.miniCox.usps}
      />

      <Section className="infos">
        <Container>
          <Paragraph>
            <strong>„Mini-Cox“ ®</strong> stammt von der alten Apfelsorte „Cox
            Orangen Renette“ ab, die als die geschmacklich beste Apfelsorte
            bekannt ist. Die neue Sorte „Mini-Cox“ ® hat ein ebenso
            ausgezeichnetes Aroma wie die Muttersorte. Die Früchte sind
            mittelgroß bis groß und von schöner roter Farbe.
          </Paragraph>
          <Paragraph>
            <strong>„Mini-Cox“ ®</strong> ist eine echte genetische Zwergform;
            sie wird auch nach Jahren im Garten nicht größer als 1,50 m.{' '}
            <strong>„Mini-Cox“ ®</strong> wurde durch Kreuzung aus "Cox Orangen"
            in mehreren Kreuzungsschritten unter Einbeziehung noch anderer alter
            Apfelsorten wie "Jonathan" und "Geheimrat Oldenburg" gezüchtet. Den
            hervorragenden Geschmack hat sie - wie bereits erwähnt – von "Cox
            Orangen" geerbt, die rote Fruchtfarbe ist auf "Jonathan" und die
            jährlich starke Blühwilligkeit und viel Ertrag ist auf die alte
            Sorte "Geheimrat Oldenburg" zurückzuführen.
          </Paragraph>
          <Paragraph>
            <strong>„Mini-Cox“ ®</strong> blüht sehr stark und setzt dann zu
            viele Früchte an. Die Früchte werden zu klein. Der Baum erschöpft
            sich dadurch und blüht im nächsten Jahr nicht. Deshalb muß im Juni
            nach dem Junifruchtfall ausgedünnt werden. Im Abstand von etwa 15 cm
            wird nur eine Frucht belassen, die übrigen werden entfernt.
          </Paragraph>
          <Paragraph className="is-last">
            <strong>„Mini-Cox“ ®</strong> ist eine Sorte mit hoher
            Ertragsleistung.
          </Paragraph>
        </Container>
      </Section>

      <Section className="characteristics">
        <Container>
          <Characteristic title="Wuchs">
            Extrem schwachwachsend - die Sorte ist ein genetischer Zwerg, also
            keine Mutation.
          </Characteristic>
          <Characteristic title="Besonderheit">
            Trotz extremer Zwergwüchsigkeit Ausbildung mittelgroßer bis großer
            Früchte.
          </Characteristic>
          <Characteristic title="Abstammung">
            Aus mehreren Kreuzungsschritten mit Cox Orangen Renette als
            Hauptsorte mit Jonathan und Geheimrat Oldenburg als weitere
            Elternsorten.
          </Characteristic>
          <Characteristic title="Ziel">
            Erzeugung zwergwüchsiger Formen. "Mini-Cox" ® ist aus Kreuzung
            derartiger Zwergformen entstanden.
          </Characteristic>
          <Characteristic title="Frucht">
            Mittelgroß (etwa wie "Rubinette") bis groß, Fruchtfarbe leuchtend
            helles Rot.
          </Characteristic>
          <Characteristic title="Baumreife">September/Oktober</Characteristic>
          <Characteristic title="Genußreife">
            Ab September bis Januar im Normallager.
          </Characteristic>
          <Characteristic title="Geschmack">
            Sehr gut, knackig, Aroma ähnlich wie Cox Orangen Renette.
          </Characteristic>
          <Characteristic title="Krankheiten" isLast>
            Gering anfällig für Schorf und Mehltau.
          </Characteristic>
        </Container>
      </Section>

      <Section>
        <Container>
          <Award />
        </Container>
      </Section>

      <Section>
        <Container>
          <Gallery images={siteData.miniCox.images} />
        </Container>
      </Section>

      <Hint hint={siteData.miniCox.hints[0]} />

      <Addresses
        title={siteData.miniCox.mailOrderTitle}
        type="mailOrder"
        addresses={siteData.miniCox.mailOrder}
      />

      <Hint
        hint={siteData.miniCox.hints[1]}
        hintUrl={siteData.miniCox.hintUrl.url}
      />

      <Addresses
        title={siteData.miniCox.licenseesTitle}
        type="licensees"
        addresses={siteData.miniCox.licensees}
      />
    </DefaultLayout>
  )
}

export default React.memo(MiniCox)
