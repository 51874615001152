import React from 'react'

const Award = () => {
  return (
    <div className="award">
      <img src="../images/mini_cox/ipm-logo.gif" alt="IPM Logo" />
      <span>
        „Mini-Cox“ ® ist Gewinner in der Kategorie "Gehölze" auf der
        Internationalen Pflanzenmesse Essen 2009.
      </span>
    </div>
  )
}

export default React.memo(Award)
